@font-face {
  font-family: "BusoramaBold";
  src: local("BusoramaBold"),
    url("./fonts/itc-busorama-bold.otf") format("truetype")
}

img {
  width: 100%;
  height: auto;
}


a,
button {
  font-family: "BusoramaBold", Helvetica, sans-serif !important;
  text-decoration: none;
}

h1 {
  font-family: "BusoramaBold", Helvetica, sans-serif !important;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: "BusoramaBold", Helvetica, sans-serif !important;
}

p {
  font-size: 18px !important;
  font-family: "BusoramaBold", Helvetica, sans-serif !important;
  color: #fff !important;
}

.app-logo {
  max-width: 140px;
}


.footer-logo {
  max-width: 180px;
  margin-bottom: 20px;
}

.menu-desktop {
  align-items: center;
}

.logo-container {
  margin-right: 50px;
}

.header-logo-link {
  display: flex;
}

.main-toolbar {
  padding: 24px 0 20px 0;
}

.menu-icon-button {
  border: 3px solid #cdc6a8 !important;
  border-radius: 0px !important;
}

.menu-icon-button svg {
  fill: #cdc6a8 !important;
}

.home {
  display: flex;
  flex-direction: column;
}

.home-lineup {
  margin: 20px 0 0 0;
  padding: 0 10px;
}

.artist-image {
  height: 200px;
}

.title {
  margin-bottom: 20px !important;
  color: #c984a7 !important;
  font-size: 24px !important;
}

.accessibility a,
.info a {
  color: #397fd3;
}

.text {
  margin-bottom: 20px !important;
}

.main-content {
  background-image: url('./images/assets/artwork.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}


.accessibility-content,
.info-content {
  margin: 20px 0;
}

.text-section {
  color: #232320;
}

.line-up-text-container {
  background-color: #c984a7 !important;
}

.line-up-text {
  text-align: center;
  text-transform: uppercase;
  color: #232320 !important;
  letter-spacing: 1px;
  margin-bottom: 0 !important;
  font-family: "BusoramaBold", Helvetica, sans-serif !important;
  font-size: 18px !important;
}

.home-details {
  margin: 15px 0 15px 0;
  padding: 24px 0;
}

.home-details-container {
  display: flex;
  flex-direction: column;

}

.home-tickets-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-details-container h3 {
  font-family: "BusoramaBold", Helvetica, sans-serif !important;
  margin: 0;
  letter-spacing: 1px;
  text-align: center;
  font-size: 16px;
}

.home-tickets-container h3 {
  font-family: "BusoramaBold", Helvetica, sans-serif !important;
  margin: 0;
  letter-spacing: 1px;
  text-align: center;
  font-size: 16px;
}

.home-details-container h2 {
  text-align: center;
}

.home-tickets-container h2,
.home-details-container h2 {
  letter-spacing: 1px;
  margin: 0;
}

.home-tickets-container h2 {
  letter-spacing: 1px;
  text-align: center;
  font-size: 1.5em;
  color: #cdc6a8;
}

.home-details-container h4 {
  letter-spacing: 1px;
  margin-bottom: 0;
  text-align: center;
}

.home-details-container p {
  font-size: 12px;
  margin-top: 10px;
  font-family: "BusoramaBold", Helvetica, sans-serif !important;
  text-align: center;
}

.line-up-card {
  box-shadow: none !important;
  border-radius: 0 !important;
  background-color: #232320 !important;
}

.home-tickets {
  width: 100%;
  align-self: center;
  margin: 0 0 30px 0;
  padding: 14px 0;
}

.MuiDrawer-paper {
  width: 75%;
  background-color: #c984a7 !important;
}

.subscribe-inner {
  display: flex;
  flex-direction: column;
  color: #232320;
  align-items: center;
  margin-bottom: 20px;
}

.subscribe-inner h3 {
  font-family: "BusoramaBold", Helvetica, sans-serif !important;
  color: #232320;
  font-size: 22px;
  font-weight: normal;
  letter-spacing: 1px;
  margin: 0;
}

.subscribe-inner p {
  margin-top: 20px;
}


.footer-section {
  color: #232320;
  text-align: center;
}

.subscribe-button {
  margin-right: 20px;
}



.footer-section {
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
}


.footer-section:last-child {
  margin-bottom: 0;
}


.footer-bottom>p {
  color: #232320 !important;
  text-align: center;
  font-size: 14px !important;
}

.footer-bottom p {
  font-size: 12px;
  margin-bottom: 0;
}


.email-link {
  color: #232320;
  font-weight: normal;
  font-size: 16px;
  text-decoration: none;
  margin-top: 10px;
  display: block;
  word-wrap: break-word;
  letter-spacing: 1px;
}

.email-link:hover {
  color: #232320;
}

.social-icons {
  margin-top: 20px;
}

.footer-social-container h3 {
  font-family: "BusoramaBold", Helvetica, sans-serif !important;
  color: #232320;
  font-size: 22px;
  font-weight: normal;
  letter-spacing: 1px;
  margin: 0;
}

.footer-link {
  text-transform: uppercase;
  color: #232320;
  font-weight: 500;
  text-decoration: none;
  width: 100%;
  margin-bottom: 10px;
  font-size: 12px;
}

.icon-footer-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: flex-start;
  margin-top: 20px;
}

.social-icons a {
  color: #232320;
  display: inline-block;
  text-decoration: none;
  margin: 0 10px;
}

.social-icons a:hover {
  color: #232320;
}

.footer-title {
  font-family: "BusoramaBold", Helvetica, sans-serif !important;
  margin: 0;
  letter-spacing: 1px;
  font-size: 1.5em;
}

.footer {
  background-color: #c984a7;
  background-size: cover;
  background-position: center center;
}

.footer-container {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0;
  align-items: center;
  min-height: 100vh;
}

.mobile-menu-link {
  width: 100%;
}

.menu-app-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.footer-social-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
}

.footer-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0 0 0;
  min-height: auto;
  width: 100%;
}

.footer-container-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

.footer-section-bottom {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 30px 0;
}


.lineup-image {
  margin-bottom: 10px;
}

.info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}

.info-container-divs.logo-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gif-image {
  max-width: 500px;
}

.info-container-divs .info-img {
  max-width: 260px;
}

.lineup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px !important;

}

.lineup-border,
.info-content {
margin-bottom:50px !important;
}

.lineup-border,
.info-content,
.border-lineup {
  background-color: #232320;
  border: 5px solid #cdc6a8;
  padding: 1rem;
  margin: 0;
}

.ticket-header-button {
  width: 100%;
  max-width: 500px;
  margin-bottom: 10px !important;
}



@media only screen and (min-width: 900px) {
  .app-logo {
    max-width: 200px;
  }

  .info-container-divs .info-img {
    max-width: 500px;
  }

  .lineup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 0 !important;
  }


  .info-container-divs.info-div {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .info-container-divs.logo-div {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .info-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    gap: 50px;
  }

  .ticket-header-button {
    width: 100%;
  }

  .border-lineup {
    border: 10px solid #cdc6a8;
    padding: 3rem;
    margin: 1.5rem 0;
  }

  .footer-container {
    flex-direction: row;
    justify-content: space-around;
  }

  .lineup-image {
    margin-bottom: 20px;
  }


  .footer-container-section {
    width: 33%;
  }

  .home-lineup {
    margin: 40px 0 15px 0;
  }

  .home-tickets-container h3,
  .home-details-container h3 {
    font-size: 1.5em;
  }

  .home-details-container h2 {
    font-size: 3em;
  }

  .home-tickets-container h4,
  .home-details-container h4 {
    font-size: 1.75em;
  }

  .home-tickets-container h2 {
    font-size: 3em;
  }

  .home-lineup {
    margin: 20px 0 0 0;
    padding: 0 24px;
  }


}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}





header {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none;
}

.no-match {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 500px;
}


.no-match h1,
.no-match p {
  color: #ffffff
}

.gallery {
  margin: 20px 0;
}

.header-button,
.ticket-header-button,
button {
  border-width: 3px !important;
}